<script setup lang="ts">
import DirectionalSwipe from '~/components/transitions/directionalSwipe.vue'

import { useGetMediaUrl } from '~/composables/useGetMediaUrl'

import type TransitionSlideDirection from '~/types/TransitionSlide'
import type VideoFormat from '~/types/videoFormat'

const emit = defineEmits(['progress', 'update:modelValue'])

type Props = {
  videos: string[]
  modelValue: number
  emitProgress?: boolean
  muted?: boolean
  autoplay?: boolean
  loopWhenFinished?: boolean
  formats: VideoFormat[]
}

const props = withDefaults(defineProps<Props>(), {
  emitProgress: false,
  muted: true,
  autoplay: true,
  loopWhenFinished: true,
})

const { getMediaUrl } = useGetMediaUrl()

const animationDirection = ref<TransitionSlideDirection>('up')
const currentDuration = ref(0)
const currentTime = ref(0)
const loaded = ref(false)

// Computed
const currentVideo = computed(() => {
  return props.videos[
    props.modelValue < 0
      ? 0
      : props.modelValue > props.videos.length
        ? props.videos.length
        : props.modelValue
  ]
})

const progress = computed(() => {
  return currentDuration.value > 0
    ? currentTime.value / currentDuration.value
    : 0
})

const sources = computed(() => {
  return props.formats.map((format) => ({
    src: getMediaUrl(`${currentVideo.value}.${format}`),
    type: format,
  }))
})

// methods
function chooseUpdate() {
  if (props.emitProgress) emit('progress', loaded ? progress.value : 0)
}

function setVideoValues(event: Event) {
  const video = event.target as HTMLVideoElement
  currentDuration.value = video.duration
  chooseUpdate()
}

function nextVideo() {
  const isRewinding =
    props.modelValue >= props.videos.length - 1 && props.loopWhenFinished
  loaded.value = false

  if (isRewinding) animationDirection.value = 'up'
  else animationDirection.value = 'down'

  nextTick(() => {
    chooseUpdate()
    currentTime.value = 0
    if (isRewinding) emit('update:modelValue', 0)
    else emit('update:modelValue', props.modelValue + 1)
  })
}

function updateCurrentTime(event: Event) {
  const video = event.target as HTMLVideoElement
  currentTime.value = video.currentTime
  chooseUpdate()
}
</script>

<template>
  <DirectionalSwipe vertical :direction="animationDirection">
    <div :key="modelValue" class="videoSplitPlayerWrapper">
      <video
        class="tw-w-full"
        :autoplay="autoplay"
        :playsinline="autoplay"
        :muted="muted"
        @canplay="loaded = true"
        @loadedmetadata="setVideoValues"
        @timeupdate="updateCurrentTime"
        @ended="nextVideo"
      >
        <source
          v-for="({ type, src }, index) in sources"
          :key="`${index}.${src}.${type}`"
          :type="`video/${type}`"
          :src="src"
        />
      </video>
    </div>
  </DirectionalSwipe>
</template>
