<script setup lang="ts">
import { storeToRefs } from 'pinia'

import VText from '~/components/ui/VText.vue'

import { useMiscResizeStore } from '~/stores/miscResize'

import { Breakpoints } from '~/enums/breakpoints'

type Slots = {
  default(): any
}
defineSlots<Slots>()

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())

const titleSize = computed(() =>
  SCREEN_WIDTH.value < Breakpoints.xs375
    ? '32'
    : SCREEN_WIDTH.value < Breakpoints.md
      ? '40'
      : '48',
)
</script>

<template>
  <VText
    :cfg="`sans/${titleSize}/bold`"
    class="mainTitleComponent tw-mx-auto tw-block tw-w-full tw-text-center"
  >
    <slot />
  </VText>
</template>

<style scoped lang="scss">
.mainTitleComponent {
  max-width: var(--page-width);
}
</style>
